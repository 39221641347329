import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Grid } from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./company.css";
import Header from "./header";
import Footer from "./footer";

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  autoplay: true,
  slidesToShow: 1,
};

export default function Contact(props) {
  return (
    <>
      <Header history={props.history} />
      <section id="section-about" className="sectionOne">
        <div className="containerr">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                fontWeight: 600,
                fontSize: 16,
                boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
              }}
            >
              <h1>Contact</h1>
              <div className="cflex">
                <div>
                  <EmailOutlinedIcon fontSize="small" />
                </div>
                <div className="pl5">
                  <a href="mailto:deepak.m@psquareenterprise.in">
                    deepak.m@psquareenterprise.in
                  </a>
                  ,{" "}
                  <a href="mailto:shanu.m@psquareenterprise.in">
                    shanu.m@psquareenterprise.in
                  </a>
                  ,{" "}
                  <a href="mailto:dia.m@psquareenterprise.in">
                    dia.m@psquareenterprise.in
                  </a>
                  ,{" "}
                  <a href="mailto:prayan.m@psquareenterprise.in">
                    prayan.m@psquareenterprise.in
                  </a>
                  ,{" "}
                  <a href="mailto:jaya.c@psquareenterprise.in">
                    jaya.c@psquareenterprise.in
                  </a>
                  ,{" "}
                  <a href="mailto:jay.s@psquareenterprise.in">
                    jay.s@psquareenterprise.in
                  </a>
                </div>
              </div>
              <div className="cflex m1">
                <LocalPhoneOutlinedIcon fontSize={"small"} />
                <div className="pl5"> +91-9825577112</div>
              </div>
              <div className="cflex m1">
                <LocationOnOutlinedIcon fontSize={"small"} />
                <div className="pl5">
                  {" "}
                  P Square E-52,53 majusar gidc vadodara - 391775
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              {/* <h2>About Us</h2> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d753.1913705065891!2d73.19154182997869!3d22.437070108121986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fcd73869a6349%3A0x7bd4cd4106004682!2sManjusar%20GIDC%20Main%20Gate!5e0!3m2!1sen!2sin!4v1661595248781!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
          </Grid>
        </div>
      </section>

      <Footer history={props.history} />
    </>
  );
}
