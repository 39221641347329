import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { getData, postDataAndImage, ServerURL } from "../FetchNodeService";
import {
  Grid,
  AppBar,
  Paper,
  TextField,
  Toolbar,
  Avatar,
  CardHeader,
} from "@material-ui/core";
import "./TRS.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TRS_Header } from "./TRS_Header";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Header from "./header";
import Footer from "./footer";
import "aos/dist/aos.css";

import Aos from "aos";
const useStyles = makeStyles({
  root: {
    width: 350,
    // height: 350,
    padding: 10,
    margin: 10,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.1s ease",
    transformStyle: "preserve-3d",
    willChange: "transform",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  homeheading: {
    textAlign: "center",
    color: "#ac9b79",
    fontSize: "2.6rem",
    fontWeight: 500,
  },
  paper: {
    height: 140,
    width: 100,
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    color: "#fff",
    height: 100,
  },
});
export const TRS = (props) => {
  const classes = useStyles();
  const [sliderList, setSliderList] = useState([]);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    autoplay: true,
    slidesToShow: 1,
  };

  useEffect(function () {
    Aos.init({
      duration: 400,
    });
  }, []);

  const ourproducts = [
    { image: "/images/Dock Bumper Pad (3).jpg", name: "Dock Bumpers" },
    { image: "/images/Black Links 2.jpg", name: "Black Links" },
    { image: "/images/Buffed Links.jpg", name: "Buffed Links" },
    { image: "/images/rcs.jpeg", name: "RCS" },
    { image: "/images/round-spacers.jpeg", name: "Round Spacer" },
    { image: "/images/punch-pad.jpeg", name: "Punch Pads" },
  ];

  useEffect(function () {
    fetchAllSlider();
  }, []);

  const fetchAllSlider = async () => {
    var list = await getData("slider/fetchallslider");
    setSliderList(list.data);
    // alert(JSON.stringify(list))
  };
  const fillslider = () => {
    return sliderList.map((item) => {
      return (
        <div>
          <img src={`${ServerURL}/images/${item.icon}`} width="100%" alt="" />
        </div>
      );
    });
  };

  return (
    <div>
      <Header history={props.history} />
      <Slider {...settings}>
        {/* <div> */}
        {fillslider()}
        {/* </div> */}
        {/* <div>
                        <img src="/slider1.jpg" width='100%' alt="" />
                    </div>
                    <div>
                        <img src="/slider2.jpg" width='100%' alt="" />
                    </div>
                    <div>
                        <img src="/slider3.jpg" width='100%' alt="" />
                    </div>
                    <div>
                        <img src="/slider4.jpg" width='100%' alt="" />
                    </div> */}
      </Slider>

      <div className="center">
        <div style={{ width: "90%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {/* <Paper elevation={0} className={classes.center} style={{ backgroundColor: '#3498db', }}> */}
              <div>
                <h1 style={{ marginTop: 0 }}>About Us</h1>
                {/* <h3>Be part of the virtuous circle</h3> */}
              </div>

              <p style={{ textAlign: "justify", lineHeight: 1.5 }}>
                <span style={{ fontWeight: 700 }}>P Square Enterprise</span>,
                established in 2011,at E-52, G.I.D.C Manjusar, Tal. Savli,
                Vadodara Gujarat is among the most recognised manufacturer of
                recycled rubber Products from used tyres, from nylon waste and
                engineered products die-cut from end-of-life tyres. PSE has a
                highly structured management system consisting of ISO 9001: 2015
              </p>
              <p style={{ textAlign: "justify", lineHeight: 1.5 }}>
                Composite Products are value added products manufactured from
                100% recycled materials. The environmentally friendly products
                are highly durable and strong, making them exceptional
                replacement for wood and other materials in a variety of
                applications. The products are well suited for application in
                sectors such as oil & gas, aviation, marine, industrial.
              </p>
              <p style={{ textAlign: "justify", lineHeight: 1.5 }}>
                The company's financials today are a testimony to our positive
                and long standing relationships with our clients.
              </p>
              {/* <div>
                                <span className="btn-outline-secondary" onClick={() => props.history.push('/technology')}>Technology</span>
                            </div> */}
              {/* </Paper> */}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Paper elevation={0} className={classes.center} style={{ backgroundColor: '#3498db', }}> */}
              {/* <img className="img-fluid" src="/p6.jpg" alt="" /> */}
              <video width="100%" height="100%" controls autoplay>
                <source src="/video/km_20220913_720p.mp4" type="video/mp4" />
              </video>
              {/* </Paper> */}
            </Grid>
            {/* <Grid item xs={12} md={4}>
                            <Paper elevation={0} className={classes.center} style={{ backgroundColor: '#3498db', }}>
                            <h2>Compounding Solutions</h2>
                            <p>Engineered to deliver groundbreaking compounding applications, TyreXol™ is a versatile, sustainable and cost effective recycled Rubber Powder. TyreXol™&nbsp;activated powders customised by our Formulation Experts provide cutting edge performance to polymer products in multiple market segments.</p>
                            <footer>
                                <span className="btn-outline-secondary">Market Applications</span>
                            </footer>
                            </Paper>
                        </Grid> */}
          </Grid>
        </div>
      </div>

      {/* <section className="section-content">
                <div className="section-container">
                    <div className="row">
                        <article className="col-lg-4">
                            <header>
                                <h1>Benefits<br />for all!</h1>
                                <h3>Be part of the virtuous circle</h3>
                            </header>
                            <h2>Tyre Recycling Solutions</h2>
                            <p>TRS proprietary technologies give Recyclers optimal value creating opportunities. Gain market value and tap new profit channels together as our Partner. Increase your profitability by partnering with TRS.</p>
                            <footer>
                                <span className="btn-outline-secondary" onClick={() => props.history.push('/technology')}>Technology</span>
                            </footer>
                        </article>
                        <article className="col-img-special">
                            <img className="img-fluid" src="/p6.jpg" alt="" />
                        </article>
                        <article className="col-lg-4">
                            <header>
                                &nbsp;
                            </header>
                            <h2>Compounding Solutions</h2>
                            <p>Engineered to deliver groundbreaking compounding applications, TyreXol™ is a versatile, sustainable and cost effective recycled Rubber Powder. TyreXol™&nbsp;activated powders customised by our Formulation Experts provide cutting edge performance to polymer products in multiple market segments.</p>
                            <footer>
                                <span className="btn-outline-secondary">Market Applications</span>
                            </footer>
                        </article>
                    </div>
                </div>
            </section> */}

      <div className="center">
        <div style={{ width: "90%" }}>
          <div className="section-content-2 center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Hidden smUp>
                <h2>Our Products</h2>
                <div
                  style={{ border: "1px solid #54a51c", width: "25%" }}
                ></div>
              </Hidden>
              <Hidden smDown>
                <h1>Our Products</h1>
                <div
                  style={{ border: "2px solid #54a51c", width: "25%" }}
                ></div>
              </Hidden>
            </div>
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <p style={{ textAlign: "center", lineHeight: 1.5 }}>
                P Square Enterprise has grown in leaps and bounds over the years
                following its inception. In able hands of Mrs Shanu Mehendiratta
                (Proprietor), a diverse workforce of the highest caliber and a
                strong infrastructure the company’s portfolio of diversified
                services by exceeding client expectations and achieving market
                leadership.
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {ourproducts.map((item) => {
                return (
                  <Card className={classes.root}>
                    <CardMedia
                      className={classes.media}
                      image={item.image}
                      title={item.name}
                    />
                    <CardContent>
                      <Typography variant="h5" component="h3">
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
              {/* <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="140"
                    className="img-fluid-d-block"
                    image="/images/Dock Bumper Pad (3).jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography variant="h4" component="h2">
                      Dock Bumpers
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card> */}
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer history={props.history} />
    </div>
  );
};
