import React from 'react'
import { Grid, AppBar, Toolbar, Hidden } from '@material-ui/core'
import './TRS.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Header from './header';
import "./TRS_intelligent.css"
import { TRS_Header } from './TRS_Header';
import Header from './header';
import Footer from './footer';
export const TRS_for_governments = (props) => {
    return (
        <div>
            <Header history={props.history} />

            <section className="section-teaser" style={{marginTop:30}}>
                <div className="mycontainer">
                    <div className="myrow">
                        <Grid container spacing={0} >
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h1>BENEFITS</h1>
                                    <h2>FOR GOVERNMENTS</h2>
                                    <p>TRS aims to be a key partner to government by solving several problems at once. With our clean technologies, we not only help to eliminate the problem of end-of-life tyres, but also to develop downstream manufacturing markets, creating employment and value for the economy.</p>
                                    <p><b>THE FUTURE IS «TECOLOGY»</b></p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col44">
                                    <img className="imgfluid" src="/benefits-for-governments.jpg" alt="" typeof="foaf:Image" />
                                    
                                </article>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <Footer history={props.history} />
        </div>
    )
}


