import React from 'react'
import { Grid, AppBar, Toolbar, Hidden } from '@material-ui/core'
import './TRS.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Header from './header';
import "./TRS_intelligent.css"
import { TRS_Header } from './TRS_Header';
import Header from './header';
import Footer from './footer';
export const TRS_for_goods = (props) => {
    return (
        <div>
            <Header history={props.history} />

            <section className="section-teaser" style={{marginTop:30}}>
                <div className="mycontainer">
                    <div className="myrow">
                        <Grid container spacing={0} >
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h1>BENEFITS</h1>
                                    <h2>FOR GOODS<br/> MANUFACTURERS AND<br/> COMPOUNDERS</h2>
                                    <p>Tyre rubber is a high-performance, engineered material that is worth a second life. Work with us to improve the performance, ecological footprint and cost of your products, and comply with future recycled content regulations.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col44">
                                    <img className="imgfluid" src="/benefits-for-good-manufacturers-and-compounders.jpg" alt="" typeof="foaf:Image" />
                                    
                                </article>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <Footer history={props.history} />
        </div>
    )
}


