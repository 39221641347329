import React, { useState, useEffect } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
// import ShoppingBasket from '@material-ui/icons/ShoppingCart';
import MoreIcon from "@material-ui/icons/MoreVert";
import {
  getData,
  ServerURL,
  postData,
  postDataAndImage,
} from "../FetchNodeService";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button, Divider, Popper } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Home from "./TRS";
// import './TRS.css'
import { LocalMall, ShoppingBasket } from "@material-ui/icons";
import "aos/dist/aos.css";
import Aos from "aos";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMEl, setAnchorMEl] = React.useState(null);
  const [mySAnchorEl, setSMyAnchorEl] = React.useState(null);
  const [SubCategory, setSubCategory] = useState([]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [category, setCategory] = useState([]);
  const [refresh, setRefresh] = useState("");

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMClick = (event) => {
    if (anchorEl == null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMClose = () => {
    setAnchorEl(null);
  };
  const opens = Boolean(anchorEl);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(function () {
    Aos.init({
      easing: "ease",
      duration: 100,
      delay: 0,
    });
  }, []);

  /////////////////////////////////////////////////////
  const fetchcategory = async () => {
    var list = await getData("categories/fetchallcategories");
    // var list = await getData('categories/fetchallcategories1')

    setCategory(list.data);
  };
  useEffect(function () {
    fetchcategory();
  }, []);
  const [categoryid, setCategoryId] = useState("");
  const handleMyMenuOpen = (event) => {
    var i = event.currentTarget.value;
    setMyAnchorEl(event.currentTarget);
    setCategoryId(i);
    fetchAllSubcategories(i);
  };

  const fetchAllSubcategories = async (item, data) => {
    // var body = {
    //   "categoryid": id
    // }
    // var list = await postData('subcategory/fetchallsubcategoriesbyid', body)
    // if (list.length > 0) { setSubCategory(list) }
    console.log(item);
    const arr = [{ subcategoryname: item.categoriesname }, ...data];
    console.log(arr);
    setSubCategory(arr);
  };

  const handleMenuClick = async (event, item, data) => {
    // alert(item.categoryid);
    // setSMyAnchorEl(item.categoryid);
    // if (item.categoryid == true) {
    var name = `/${data}`;
    props.history.push({ pathname: name });
    // setAnchorMEl(null);
    // }
    // else {

    // }
  };

  const mouseEnter = async (event, item, data) => {
    if (item.subcategory.length) {
      setAnchorMEl(event.currentTarget);
      fetchAllSubcategories(item, item.subcategory);
    }
  };

  const mouseClose = async (event, item, data) => {
    // setAnchorMEl(null);
    setTimeout(() => {
      setAnchorMEl(null);
    }, 5000);
  };

  const handleMElClose = () => {
    setAnchorMEl(null);
  };
  const fillcategory = () => {
    return category.map((item) => {
      {
        return (
          <li
            className="nav-item"
            value={item.categoryid}
            onMouseEnter={(event) =>
              mouseEnter(
                event,
                item,
                item.categoriesname.replace(/ /g, "-").toLowerCase()
              )
            }
            onMouseLeave={(event) =>
              mouseClose(
                event,
                item,
                item.categoriesname.replace(/ /g, "-").toLowerCase()
              )
            }
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0.75rem 0.7em",
              color: "#003764",
              fontWeight: 800,
            }}
          >
            <span
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(event) =>
                handleMenuClick(
                  event,
                  item,
                  item.categoriesname.replace(/ /g, "-").toLowerCase()
                )
              } /* onClick={() => handleRender(item.categoriesname.replace(/ /g, "-").toLowerCase())} */
            >
              {item.categoriesname}
            </span>
          </li>
          // <Button
          //   style={{ color: "#000", marginRight: 25 }}
          //   value={item.categoryid}
          //   onClick={(event) => handleMenuClick(event)}
          // >
          //   {item.categoriesname}
          // </Button>
        );
      }
    });
  };

  const handleRender = (data) => {
    // alert(data)
    var name = `/${data}`;
    // alert(name)
    props.history.push({ pathname: name });
  };

  const displaPic = () => {
    return SubCategory.map((item) => {
      return (
        <div onClick={() => handleClick(item)}>
          <li
            style={{ padding: 3 }}
            onClick={() =>
              handleRender(
                item.subcategoryname.replace(/ /g, "-").toLowerCase()
              )
            }
          >
            {item.subcategoryname}
          </li>
        </div>
      );
    });
  };

  const [styles, useStyless] = useState({
    display: "flex",
    listStyle: "none",
    flexDirection: "column",
    color: "#886620",
    fontSize: 16,
    width: 200,
    flexWrap: "wrap",
    MozColumnCount: 3,
    WebkitColumnCount: 3,
    columnCount: 3,
    cursor: "pointer",
    textTransform: "uppercase",
  });
  const handleClick2 = (event) => {
    props.history.push(
      { pathname: "/subcategoryimages" },
      { categoryid: categoryid }
    );
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleClick = (item) => {
    props.history.push({ pathname: "" }, { subcategoryid: item.subcategoryid });
  };
  const [scrolled, setScrolled] = useState(false);
  const [myAnchorEl, setMyAnchorEl] = React.useState(null);
  const handleClose = () => {
    // setCategoryId(null)
  };

  const submenu = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
        onMouseLeave={() => setMyAnchorEl(null)}
      >
        <MenuItem
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            backgroundColor: "#fff",
          }}
          onClick={handleClose}
        >
          {" "}
          {displaPic()}
        </MenuItem>
      </div>
    );
  };
  return (
    <>
      {/* <div className={classes.root}> */}
      {scrolled ? (
        <>
          <Hidden smDown>
            <AppBar
              style={{ backgroundColor: "#fff", position: "sticky", top: 0 }}
            >
              <Toolbar
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <IconButton
                    edge="end"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                  >
                    <img
                      src="/logo_new.png"
                      alt="TRS - Tyre Recycling Solutions"
                      onClick={() => props.history.push("/")}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>Home</span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/about")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>About</span>
                  </li>

                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/contact")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>Contact</span>
                  </li>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {fillcategory()}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorMEl}
                  keepMounted={false}
                  open={Boolean(anchorMEl)}
                  onClose={handleMElClose}
                  getContentAnchorEl={null}
                  PaperProps={{
                    style: {
                      marginTop: 42,
                      borderRadius: 0,
                      backgroundColor: '#fff', zIndex: 50,
                    },
                  }}
                  MenuListProps={{
                    style: {
                      zIndex: 50,
                    }
                  }}
                  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', zIndex: 50 }}
                // anchorOrigin={{ vertical: "top", horizontal: "center top" }}
                // transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  {submenu()}

                </Menu>
              </div> */}
                {/* <div>
                <div className="contact-links"> */}
                {/* <span className="btn-primary" role="button" aria-pressed="true">
                  USE Tyrexol
                  <sup>TM</sup> POWDER
                </span> */}
                {/* &nbsp;&nbsp;
                  <span onClick={() => props.history.push('/trs-register')} className="btn-primary">
                    BECOME A RECYCLING PARTNER
                  </span> */}
                {/* </div>
              </div> */}
              </Toolbar>
            </AppBar>
          </Hidden>
          <Hidden smUp>
            <AppBar
              style={{ backgroundColor: "#fff", position: "sticky", top: 0 }}
            >
              <Toolbar
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src="/logo_new.png"
                  alt="TRS - Tyre Recycling Solutions"
                  onClick={() => props.history.push("/")}
                />
                {/* <div className="contact-links">
                <span className="btn-primarys" role="button" aria-pressed="true">
                  USE Tyrexol
                  <sup>TM</sup> POWDER
                </span>
                &nbsp;&nbsp;
                <span onClick={() => props.history.push('/trs-register')} className="btn-primarys">
                  BECOME A RECYCLING PARTNER
                </span>
              </div> */}
                <div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMClick}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={opens}
                    onClose={handleMClose}
                    PaperProps={{
                      style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: "100%",
                        marginTop: 50,
                      },
                    }}
                    MenuListProps={{
                      style: {
                        zIndex: 50,
                      },
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      zIndex: 50,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>Home</span>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/about")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>About</span>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/contact")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>Contact</span>
                      </li>
                    </div>
                    {/* <MenuItem onClick={handleMClose} style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                    alignItems: 'flex-start', backgroundColor: '#fff'
                  }}>
                    {fillcategory()}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorMEl}
                      keepMounted
                      open={Boolean(anchorMEl)}
                      onClose={handleMElClose}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          // maxHeight: ITEM_HEIGHT * 4.5,
                          marginTop: 22,
                          borderRadius: 0,
                          boxShadow: 'none',
                          width: '100%',
                          backgroundColor: '#fff'
                        },
                      }}
                      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}
                    // anchorOrigin={{ vertical: "top", horizontal: "center top" }}
                    // transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      {submenu()}

                    </Menu>
                  </MenuItem> */}
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden smDown>
            <AppBar
              style={{ backgroundColor: "#fff", position: "sticky", top: 0 }}
            >
              <Toolbar
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  edge="end"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <img
                    src="/logo_new.png"
                    alt="TRS - Tyre Recycling Solutions"
                    onClick={() => props.history.push("/")}
                  />
                </IconButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>Home</span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/about")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>About</span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => props.history.push("/contact")}
                    style={{
                      padding: "0.75rem 0.7em",
                      color: "#003764",
                      fontWeight: 800,
                    }}
                  >
                    <span>Contact</span>
                  </li>
                </div>
                {/* <li className="nav-item" onClick={() => props.history.push('/home')} style={{ padding: '0.75rem 0.7em', color: '#003764', fontWeight: 800 }}>
                  <span >
                    Home
                  </span>
                </li>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {fillcategory()}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorMEl}
                    keepMounted
                    open={Boolean(anchorMEl)}
                    onClose={handleMElClose}
                    getContentAnchorEl={null}
                    PaperProps={{
                      style: {
                        marginTop: 42,
                        borderRadius: 0,
                        backgroundColor: '#fff'
                      },
                    }}
                    MenuListProps={{
                      style: {
                        zIndex: 50,
                      }
                    }}
                    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', zIndex: 50 }}
                  >
                    {submenu()}

                  </Menu>
                </div> */}
                {/* <div className="contact-links">
                  <span className="btn-primary" role="button" aria-pressed="true">
                    USE Tyrexol
                    <sup>TM</sup> POWDER
                  </span>
                  &nbsp;&nbsp;
                  <span onClick={() => props.history.push('/trs-register')} className="btn-primary">
                    BECOME A RECYCLING PARTNER
                  </span>
                </div> */}
              </Toolbar>
            </AppBar>
          </Hidden>
          <Hidden smUp>
            <AppBar
              style={{ backgroundColor: "#fff", position: "sticky", top: 0 }}
            >
              <Toolbar
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src="/logo_new.png"
                  alt="TRS - Tyre Recycling Solutions"
                  onClick={() => props.history.push("/")}
                />
                {/* <div className="contact-links">
                  <span className="btn-primarys" role="button" aria-pressed="true">
                    USE Tyrexol
                    <sup>TM</sup> POWDER
                  </span>
                  &nbsp;&nbsp;
                  <span onClick={() => props.history.push('/trs-register')} className="btn-primarys">
                    BECOME A RECYCLING PARTNER
                  </span>
                </div> */}
                <div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMClick}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={opens}
                    onClose={handleMClose}
                    PaperProps={{
                      style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: "100%",
                        marginTop: 50,
                      },
                    }}
                    MenuListProps={{
                      style: {
                        zIndex: 50,
                      },
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      zIndex: 50,
                    }}
                  >
                    {/* <MenuItem onClick={handleMClose} style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                      alignItems: 'flex-start', backgroundColor: '#fff'
                    }}>
                      {fillcategory()}
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorMEl}
                        keepMounted
                        open={Boolean(anchorMEl)}
                        onClose={handleMElClose}
                        getContentAnchorEl={null}
                        PaperProps={{
                          style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            marginTop: 42,
                            borderRadius: 0,
                            backgroundColor: '#fff',
                            width: '100%',
                            boxShadow: 'none'
                          },
                        }}
                        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}
                      // anchorOrigin={{ vertical: "top", horizontal: "center top" }}
                      // transformOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        {submenu()}

                      </Menu>
                    </MenuItem> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>Home</span>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/about")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>About</span>
                      </li>

                      <li
                        className="nav-item"
                        onClick={() => props.history.push("/contact")}
                        style={{
                          padding: "0.75rem 0.7em",
                          color: "#003764",
                          fontWeight: 800,
                        }}
                      >
                        <span>Contact</span>
                      </li>
                    </div>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
          </Hidden>
        </>
      )}
      {/* </div> */}
    </>
  );
}
