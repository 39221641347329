import React from 'react'
import { Grid, AppBar, Toolbar, Hidden } from '@material-ui/core'
import './TRS.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Header from './header';
import "./TRS_intelligent.css"
import { TRS_Header } from './TRS_Header';
import Header from './header';
import Footer from './footer';
export const TRS_corporate_governance = (props) => {
    return (
        <div>
            <Header history={props.history} />

            <section className="section-teaser" style={{ marginTop: 30 }}>
                <div className="mycontainer">
                    <div className="myrow">
                        <Grid container spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Grid item xs={12} md={8}>
                                <article className="col33">
                                    <h1>GOOD CORPORATE<br />
                                        GOVERNANCE DRIVES<br />
                                        OUR DECISION-MAKING<br />
                                        PROCESS</h1>
                                    <p>Tyre Recycling Solutions SA is committed to good corporate governance, which promotes the long-term interests of shareholders, strengthens Board and management accountability and helps build stakeholders trust in the Company.</p>
                                    <p>The Board is elected by the shareholders to oversee their interest in the long-term sustainability and the success of the business and its financial strength. The Board serves as the ultimate decision making body of the Company, except for those matters reserved to or shared with the shareholders. The Board selects and oversees the members of senior management, who are charged by the Board with conducting the business of the Company.</p>
                                    <p>Our Company does the utmost to preclude any conflict of interest. For instance, the CEO is a co-founder of the Company, but does not sit on the Board.</p>
                                    <p>The Board has created three committees with specific responsibilities. Such committees improve the interaction within the Board and facilitate the communication between the Board and the management. Frequent contacts between the Board and the Management also ensure the follow up on the strategy implementation.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <article className="col44">
                                    <img className="imgfluid" src="/header-corporate-governance.png" alt="" typeof="foaf:Image" />

                                </article>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section id="section-benefits" className="sectionbenefits">
                <div className="containerbenefits">
                    <div className="rowbeni">
                        <article className="colbeni">
                            <h1>CORPORATE GOVERNANCE<br />
                                STRUCTURE</h1>
                        </article>
                    </div>
                </div>
            </section>
            <section className="section-teaser">
                <div className="mycontainer">
                    <div className="myrow">
                        <article className="col33">
                            <h1>OUR BOARD OF DIRECTORS</h1>
                        </article>
                    </div>
                </div>
            </section>
            <section className="section-teaser" style={{ marginTop: -50 }}>
                <div className="mycontainer">
                    <div className="myrow">
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>PIERRE KLADNY, CHAIRMAN</h3>
                                    <p>Engineer dipl. EPFL, MSc, Postgrad CRPM</p>
                                    <p>Founder of Valley Road Capital, Pierre Kladny is a serial entrepreneur bringing technological and industrial experience. He has founded and managed 5 companies. He has sold one of his businesses to a large multinational firm and has divested another one through a management buy-in scheme. Prior to founding ValleyRoad Capital, he spent 3 years with Lombard Odier Darier Hentsch & Cie, Private Bankers in Geneva.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>PER SETTERBERG, BOARD MEMBER</h3>
                                    <p>Degree in Business Administration, University of Lund, Sweden.</p>
                                    <p>Per Setterberg worked with Global Blue between 1988 and 2014. The last 16 years as President and CEO. Drove the expansion from a local presence in the Nordic countries to the world leader with operations in 41 countries. Per was formerly a senior manager for PwC and a Swedish State Authorised Accountant. He is the Chairman of Dataflow Ltd, Elevate Ltd and VFS Global.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>ROBERTO GRADNIK, BOARD MEMBER</h3>
                                    <p>Ph.D. Internal Medicine Residency Program, Università degli Studi di Milano</p>
                                    <p>Trained as a physician, Roberto Gradnik brings more than 25 years of experience as a prominent executive in the pharmaceutical and biotechnology sectors. Previously with Merck Serono, for which he was appointed Executive Vice-President for Europe and a member of the Executive Committee. He then became Executive Vice-President of the Global Business Unit for Neurodegenerative Diseases, before being appointed to the position as CEO of Stallergenes, a European biopharmaceutical company specialized in the treatment of severe respiratory allergies. Roberto Gradnik is currently CEO of Ixaltis, a French biotech startup company.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>PIAO YITONG, BOARD MEMBER</h3>
                                    <p>Ph.D. in Sustainable Design and Construction, Stanford University</p>
                                    <p>Dr. Piao is the founder & CEO of SequoiaLibra Technology Group Co, Ltd in China. She also works as a consultant of the financial investment in Longjiang Environmental Protection Group Co., Ltd. She holds a Ph.D. in Sustainable Design and Construction from Stanford University. She also holds a Master of Science in Environmental Engineering from UC Berkeley and bachelor from Harbin Institute of Technology.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>MARTIN PUGH, BOARD MEMBER</h3>
                                    <p>B.Sc (Hons) Industrial Chemistry and Management Studies, Loughborough University</p>
                                    <p>Mr. Pugh is a successful COO with broad strategic and marketing background and almost 40 years’ experience in the international petrochemical and plastics industry. Now retired from his executive position, he brings a strong background in both the formation and leading of joint ventures and more recently working with private equity and IPO flotation. Previous positions at Trinseo, Styrolution, Ineos Styrenics, Nova Innovene, Elementis, Dow Chemical and Mobil Oil.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>MATTEO MARCHISIO, OBSERVER TO THE BOARD</h3>
                                    <p>B.Sc. Economics and Finance from Università Cattolica del Sacro Cuore, Milan</p>
                                    <p>Mr. Marchisio started his career as a consultant with KPMG before moving to the rubber industry. For nearly 20 years, Mr. Marchisio successfully held various positions as General Group Manager and Country Manager at Manuli Rubber Industries in Poland, Italy and the Netherlands. In June 2017, Mr. Marchisio moved back to Poland and assumed the position of Business Unit Director Synthetic Rubber at Synthos, one of the world's leading manufacturers of synthetic rubber and tyre materials with a broad portfolio of rubbers and a strong technology roadmap focused on sustainable solutions.</p>
                                </article>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section id="section-benefits" className="sectionbenefits">
                <div className="containerbenefits">
                    <div className="rowbeni">
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={12}>
                                <article className="col33">
                                    <h1>OUR BOARD COMMITTEES</h1>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>NOMINATION AND REMUNERATION COMMITTEE</h3>
                                    <p>In charge of management evaluation and the incentive program of key people.</p>
                                    <p>Members: Pierre Kladny (Chair), Yitong Piao</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>FINANCE AND AUDIT COMMITTEE</h3>
                                    <p>Keeping check on finances, i.e. cash flow to drive the business. Report to the board at every meeting.</p>
                                    <p>Members: Per Setterberg (Chair), Yitong Piao</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h3>OPERATIONS COMMITTEE</h3>
                                    <p>Follow up on the strategic decisions.</p>
                                    <p>Members: Pierre Kladny (Chair), Roberto Gradnik</p>
                                </article>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </section>

            <Footer history={props.history} />
        </div>
    )
}


