import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./company.css";
import Header from "./header";
import Footer from "./footer";

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  autoplay: true,
  slidesToShow: 1,
};

export default function Company(props) {
  return (
    <>
      <Header history={props.history} />
      <section id="section-about" className="sectionOne">
        <div className="containerr">
          <Grid container spacing={0}>
            <Grid item xs={12} md={4}>
              <article className="col1">
                <div className="imgw">
                  <img
                    className="imgd"
                    src="/Deepak Ji.jpg"
                    alt="Deepak Mehendiratta"
                    typeof="foaf:Image"
                  />
                </div>
                {/* <h1> CTO</h1> */}
                <h2>Deepak Mehendiratta</h2>
              </article>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              style={
                {
                  // paddingLeft: "5%",
                  // paddingRight: "5%",
                }
              }
            >
              <h2 style={{ marginTop: 0 }}>About Us</h2>
              <p style={{ textAlign: "justify" }}>
                <b>P Square Enterprise</b>, established in 2011,at E-52, G.I.D.C
                Manjusar, Tal. Savli, Vadodara Gujarat is among the most
                recognised manufacturer of reclaimed rubber from used tyres,
                upscaled polyamide from nylon waste and engineered products
                die-cut from end-of-life tyres
              </p>
              <p>
                Composite Products are value added products manufactured from
                100% recycled materials. The environmentally friendly products
                are highly durable and strong, making them exceptional
                replacement for wood and other materials in a variety of
                applications. The products are well suited for application in
                sectors such as oil & gas, aviation, marine, industrial.
              </p>
              <p>
                The company's financials today are a testimony to our positive
                and long standing relationships with our clients
              </p>
              <p>
                <b>P Square Enterprise</b> has grown in leaps and bounds over
                the years following its inception. In able hands of{" "}
                <b>Mrs Shanu Mehendiratta (Proprietor)</b>, a diverse workforce
                of the highest caliber and a strong infrastructure the company’s
                portfolio of diversified services by exceeding client
                expectations and achieving market leadership.
              </p>
            </Grid>
            {/* <section id="section-about" className="sectionOne"> */}
            <Grid
              item
              xs={12}
              md={12}
              style={
                {
                  // paddingLeft: "5%",
                  // paddingRight: "5%",
                }
              }
            >
              <h2>Vision:</h2>
              <p>Our vision for P Square Enterprise is</p>
              <p>
                To be preferred, most trusted and leading Manufacturing company
                by meeting all benchmarks according to client specification.
              </p>
              <p>
                To be known for reliability, flexibility, responsiveness ,
                innovative services and an exemplary performance by encouraging
                an environment of care, mutual trust and respect with constant
                updating of industry specific knowledge and market developments
                for employees.
              </p>
              <p>
                To attain organizational excellence and continually improving
                performance throughout the organization and at all the levels.
              </p>
              {/* <p>
                To build a lasting relationship with the client by exceeding
                customer expectations and achieving market leadership and
                operating excellence in every segment of our company
              </p> */}
            </Grid>
            {/* </section> */}
          </Grid>
        </div>
      </section>

      <Footer history={props.history} />
    </>
  );
}
