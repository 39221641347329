import React from 'react'
import { Grid, AppBar, Toolbar, Hidden } from '@material-ui/core'
import './TRS.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Header from './header';
import "./TRS_intelligent.css"
import { TRS_Header } from './TRS_Header';
import Header from './header';
import Footer from './footer';
export const TRS_quality_policy = (props) => {
    return (
        <div>
            <Header history={props.history} />
            <section className="section-teaser" style={{ marginTop: 50 }}>
                <div className="mycontainer">
                    <div className="myrow">
                        <Grid container spacing={0} >
                            <Grid item xs={12} md={6}>
                                <article className="col33">
                                    <h1>SWISS QUALITY IS THE<br />
                                        #1 INGREDIENT OF OUR<br />
                                        TIRE RECYCLING SOLUTIONS.</h1>
                                    <img className="imgfluid" src="/logo_iso.jpg" width="30%" alt="" typeof="foaf:Image" />
                                    <p>Quality starts with our customers. Our solutions are tailored to match our customers’ expectations and the quality they seek for their own products.</p>
                                    <p>We have developed our product lines, formulation and compounding solutions to meet the highest possible standard.</p>
                                    <p>Continuous improvement and Employee commitment support our Quality Objectives in line with the framework laid down within ISO 9001:2015.</p>
                                    <p>Our quality management system is audited on a regular basis.</p>
                                </article>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <article className="col44">
                                    <img className="imgfluid" src="/certificate_iso_v2.jpg" alt="" typeof="foaf:Image" />
                                </article>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <Footer history={props.history} />
        </div>
    )
}