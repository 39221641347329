import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Header from './userinterface/header';
import { ToastContainer } from 'react-toastify';
import AddCategories from './administration/AddCategories'
import DisplayAllCategories from './administration/DisplayAllCategories';
import Subcategory from "./administration/subcategory"
import DisplayallSubcategory from './administration/displayallSubcategory'
import { TRS } from './userinterface/TRS';
import { TRS_Intelligent_compund } from './userinterface/TRS_intelligent_compund';
import TRS_technology from './userinterface/TRS_technology';
import TRS_expertise from './userinterface/TRS_expertise';
import TRS_Register from './userinterface/TRS_Register';
import { TRS_fortheenvironment } from './userinterface/TRS_fortheenvironment';
import { TRS_for_governments } from './userinterface/TRS_for_governments';
import { TRS_for_goods } from './userinterface/TRS_for_goods';
import { TRS_corporate_governance } from './userinterface/TRS_corporate_governance';
import { TRS_quality_policy } from './userinterface/TRS_quality_policy';
import { TRS_tyre_processors } from './userinterface/TRS_tyre_processors';
import Benefits from './userinterface/benefits';
import Company from './userinterface/company';
import Contact from './userinterface/contact';
import { Product_trs } from './userinterface/Product_trs';
import { Add_Products } from './administration/Add_Products';
import Dashboard from './administration/Dashboard';
import AddHomepage from './administration/AddHomepage';
import AdminLogin from './administration/AdminLogin';

function App(props) {
  return (
    <>
      <Router>

        <Route component={AddCategories} exact strict path="/addcategory" props={props.history} />
        <Route component={DisplayAllCategories} exact strict path="/displaycategory" props={props.history} />
        <Route component={DisplayallSubcategory} exact strict path="/displayallsubcategory" props={props.history} />
        <Route component={Subcategory} exact strict path="/addsubcategory" props={props.history} />
        <Route component={TRS} exact strict path="/" props={props.history} />
        <Route component={TRS_Intelligent_compund} exact strict path="/intelligent-compounding" props={props.history} />
        <Route component={TRS_technology} exact strict path="/technology" props={props.history} />
        <Route component={TRS_expertise} exact strict path="/our-expertise" props={props.history} />
        <Route component={TRS_Register} exact strict path="/trs-register" props={props.history} />
        <Route component={Benefits} exact strict path="/benefits" props={props.history} />
        <Route component={Company} exact strict path="/about" props={props.history} />
        {/* <Route component={Company} path="/mission" props={props.history} /> */}
        <Route component={Contact} exact strict path="/contact" props={props.history} />
        <Route component={Product_trs} exact strict path="/product" props={props.history} />
        <Route component={Product_trs} exact strict path="/product-range" props={props.history} />
        <Route component={TRS_fortheenvironment} exact strict path="/for-the-enviornment" props={props.history} />
        <Route component={TRS_for_governments} exact strict path="/for-government" props={props.history} />
        <Route component={TRS_for_goods} exact strict path="/for-goods" props={props.history} />
        <Route component={TRS_corporate_governance} exact strict path="/corporate-governance" props={props.history} />
        <Route component={TRS_quality_policy} exact strict path="/quality-policy" props={props.history} />
        <Route component={TRS_tyre_processors} exact strict path="/for-tyre-processors" props={props.history} />
        <Route component={Add_Products} exact strict path="/addproducts" props={props.history} />
        <Route component={Dashboard} exact strict path="/dashboard" props={props.history} />
        <Route component={AddHomepage} exact strict path="/addhomepage" props={props.history} />
        <Route component={AdminLogin} exact strict path="/adminlogin" props={props.history} />
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
