import React from "react";

import { Grid } from "@material-ui/core";
const Footer = () => {
  return (
    <div className="trs-footer" style={{ padding: "2rem" }}>
      <Grid container spacing={0} className="footer-container">
        {/* <Grid item xs={12} md={3}>
                    <ul className="footer-menu" style={{ color: 'white' }}>
                        <li>
                            <span>Products</span>
                            <ul>
                                <li>
                                    <span>Product Range</span>
                                </li>
                            </ul>
                        </li>
                        <li >
                            <span style={{ color: '#54a51c' }}>Technology</span>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ul className="footer-menu">
                        <li>
                            <span>Intelligent Compounding</span>
                            <ul>
                                <li>
                                    <span>Our Expertise</span>
                                </li>
                                <li>
                                    <span>Market Applications</span>
                                </li>
                                <li>
                                    <span>Case Studies</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ul className="footer-menu">
                        <li>
                            <span>Benefits</span>
                            <ul>
                                <li>
                                    <span>For Environment</span>
                                </li>
                                <li>
                                    <span>For Governments</span>
                                </li>
                                <li>
                                    <span>For Goods Manufacturers And Compounders</span>
                                </li>
                                <li>
                                                                    <span>For Tyre Processors</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ul className="footer-menu">
                        <li>
                            <span>Company</span>
                            <ul>
                                <li>
                                    <span>Mission &amp; Vision</span>
                                </li>
                                <li>
                                    <span>About us</span>
                                </li>
                                <li>
                                    <span>Our Team</span>
                                </li>
                                <li>
                                    <span>Corporate Governance</span>
                                </li>
                                <li>
                                    <span>Our Partners</span>
                                </li>
                                <li>
                                    <span>Medias</span>
                                </li>
                                <li>
                                    <span>Data Privacy Policy</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Grid> */}
        <Grid item xs={12} md={12}>
          <div className="trs-copyright">
            <div className="trs-container">
              <div className="copyright">
                © Copyright PSQUARE {new Date().getFullYear("YYYY")}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
